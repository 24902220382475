<template>
  <div>
    <TheDesktopNavbarTop
      :siteName="t('layouts.default.site')"
      class="fixed top-0 left-0 z-40 transition-transform shadow"
      :class="{
        '-translate-y-full sm:translate-y-0': scrollDirection === 'downward',
      }"
    />

    <!-- Site content -->
    <div
      class="pt-16 bg-neutral-100 text-neutral-800 dark:bg-black dark:text-neutral-200"
      id="mainContainer"
    >
      <main>
        <slot />
      </main>
    </div>

    <TheMobileExpandableSidebarLeft
      v-model="navbarOpen"
      @changeNav="changeNav"
      :nav="nav"
    />
  </div>
</template>

<script setup lang="ts">
import {
  IconQuestionMark,
  IconSettings,
  IconBook2,
  IconBooks,
  IconTrophy,
  IconPalette,
  IconMessage,
  IconNews,
} from "@tabler/icons-vue";

const nuxtApp = useNuxtApp();
const route = useRoute();
const href = useHref();
const scrollDirection = useScrollDirection();

const { t } = nuxtApp.$i18n.global;

const navbarOpen = ref(false);
const nav = computed(() => ({
  site: t("layouts.default.site"),
  current: route.path,
  items: [
    {
      name: t("layouts.default.library"),
      href: href.value.library.index,
      icon: IconBooks,
      current: false,
    },
    {
      name: t("layouts.default.discover"),
      href: href.value.discover.index,
      icon: IconBook2,
      current: false,
    },
    {
      name: t("layouts.default.feed"),
      href: href.value.feed.index,
      icon: IconMessage,
      current: false,
    },
    {
      name: t("layouts.default.news"),
      href: href.value.news.index,
      icon: IconNews,
      current: false,
    },
    {
      name: t("layouts.default.publish"),
      href: href.value.publish.overview.index,
      icon: IconPalette,
      current: false,
    },
    {
      name: t("layouts.default.contest"),
      href: href.value.contest.overview.index,
      icon: IconTrophy,
      current: false,
    },
    {
      name: t("layouts.default.settings"),
      href: href.value.settings.profile,
      icon: IconSettings,
      current: false,
    },
    {
      name: t("layouts.default.help"),
      href: "https://help.namicomi.com",
      icon: IconQuestionMark,
      current: false,
    },
  ],
}));

function changeNav(selectedNav: string) {
  nav.value.current = selectedNav;
  navbarOpen.value = false;
}
</script>
